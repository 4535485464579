// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\omnicasa-static-web\\omnicasa-static-web-service\\source\\dist\\gatsby-source\\immovanparijs\\source\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-tmpl-tsx": () => import("D:\\omnicasa-static-web\\omnicasa-static-web-service\\source\\dist\\gatsby-source\\immovanparijs\\source\\src\\templates\\page-tmpl.tsx" /* webpackChunkName: "component---src-templates-page-tmpl-tsx" */),
  "component---src-templates-demand-register-tmpl-tsx": () => import("D:\\omnicasa-static-web\\omnicasa-static-web-service\\source\\dist\\gatsby-source\\immovanparijs\\source\\src\\templates\\demand-register-tmpl.tsx" /* webpackChunkName: "component---src-templates-demand-register-tmpl-tsx" */),
  "component---src-pages-404-tsx": () => import("D:\\omnicasa-static-web\\omnicasa-static-web-service\\source\\dist\\gatsby-source\\immovanparijs\\source\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-feed-back-tsx": () => import("D:\\omnicasa-static-web\\omnicasa-static-web-service\\source\\dist\\gatsby-source\\immovanparijs\\source\\src\\pages\\feed-back.tsx" /* webpackChunkName: "component---src-pages-feed-back-tsx" */)
}

exports.data = () => import("D:\\omnicasa-static-web\\omnicasa-static-web-service\\source\\dist\\gatsby-source\\immovanparijs\\source\\.cache\\data.json")

